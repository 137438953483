'use strict'; 

angular.module('angus.constants', []);
angular.module('angus.utils', []);
angular.module('angus.services', ['angus.providers']);
angular.module('angus.providers', ['angus.services']);
angular.module('angus.directives', ['angus.services', 'ui.select2', 'ui.bootstrap.position']);
angular.module('angus.components', ['angus.services', 'ui.sortable']);
angular.module('angus.filters', ['angus.constants']);
angular.module('angus.controllers', ['angus.services']);
angular.module('angus', [
  'ngAnimate',
  'ngMessages',
  'gridster',
  'geolocation',
  'pasvaz.bindonce',
  'ngSanitize',
  'ngCookies',
  'ngAria',
  'ngResource',
  'ui.router',
  'ngRoute',
  'ui.select',
  'ui.bootstrap',
  'multi-select-ok',
  'angus.constants',
  'angus.utils',
  'fluentRest',
  'agGrid',
  'ngMaterial',
  'ngScrollbars',
  'ui.bootstrap.dropdown',
  'templates-modules',
  'angus.providers',
  'angus.services',
  'angus.directives',
  'angus.filters',
  'angus.components',
  'angus.controllers',
  'treeControl',
  'angulartics',
  'angulartics.google.analytics'
])
  .factory('briteHTTPInterceptor', function() {
    return {
      // optional method
      'request': function(config) {
        // API route, no file suffix
        if (!config.url.startsWith("http") && !config.url.match(/\.[0-9a-z]+$/i)) {
          var envApi = '';
          var envApiV2 = '';

          if (typeof window !== 'undefined' && window.hasOwnProperty("env")) {
            envApi = window.env.envApi;
            envApiV2 = window.env.envApiV2;
          }

          if (config.apiV2) {
            config.url = envApiV2 + (config.url.startsWith("/") ? config.url : ('/' + config.url));
          }
          else {
            config.url = envApi + (config.url.startsWith("/") ? config.url : ('/' + config.url));
          } 
        }
        
        return config;
      }
    };
  })
  .config(function ($httpProvider) {
      $httpProvider.defaults.withCredentials = true;
      $httpProvider.interceptors.push('briteHTTPInterceptor');
  })
  .config(function ($mdThemingProvider) {
    $mdThemingProvider.theme('default')
      .primaryPalette('blue', {
        'default': '600'
      });


    $mdThemingProvider.theme('dark-grey').backgroundPalette('grey').dark();
    $mdThemingProvider.theme('dark-orange').backgroundPalette('orange').dark();
    $mdThemingProvider.theme('dark-purple').backgroundPalette('deep-purple').dark();
    $mdThemingProvider.theme('dark-blue').backgroundPalette('blue').dark();

  })
  .config(function (ScrollBarsProvider) {
    // the following settings are defined for all scrollbars unless the
    // scrollbar has local scope configuration
    ScrollBarsProvider.defaults = {
      scrollButtons: {
        scrollAmount: 'auto', // scroll amount when button pressed
        enable: false // enable scrolling buttons by default
      },
      scrollInertia: 400, // adjust however you want
      axis: 'y', // enable 2 axis scrollbars by default,
      theme: 'dark',
      autoHideScrollbar: true
    };
  })
  .config(['fluentRestConfigProvider', '$provide', '_', function (fluentRestConfigProvider, $provide, _) {

    $provide.decorator('$q', ['$delegate',
      function ($delegate) {

        function decoratePromise(promise) {

          promise.spread = function (resolve, reject) {
            return this.then(function (value) {
              if (!_.isArray(value))
                value = [value];

              return resolve.apply(this, value);
            });
          };

          return promise;
        }

        var defer = $delegate.defer,
          when = $delegate.when,
          reject = $delegate.reject,
          all = $delegate.all;

        $delegate.defer = function () {
          var deferred = defer();
          decoratePromise(deferred.promise);
          return deferred;
        };

        $delegate.when = function () {
          return decoratePromise(when.apply(this, arguments));
        };

        $delegate.reject = function () {
          return decoratePromise(reject.apply(this, arguments));
        };

        $delegate.all = function () {
          return decoratePromise(all.apply(this, arguments));
        };

        return $delegate;
      }
    ]);

    var endpoints = [
      'access',
      'accounting',
      'actuals',
      'adders',
      'adjustments',
      'aggregates',
      'approve',
      'automap',
      'batches',
      'billingCycle',
      'bills',
      'budgets',
      'budgetsByDate',
      'budgetsTankGainLoss',
      'budgetProducts',
      'byDate',
      'byDay',
      'callbacks',
      'categories',
      'comments',
      'containers',
      'copy',
      'costing',
      'counts',
      'create',
      'customerAccounts',
      'dates',
      'dealers',
      'delete',
      'deliveries',
      'deliveryEfficiencySummary',
      'details',
      'diffs',
      'distinct',
      'divisions',
      'etl',
      'existing',
      'exceptions',
      'forDealers',
      'finalBill',
      'finance',
      'gains',
      'gainsLosses',
      'generate',
      'goals',
      'gridState',
      'groups',
      'hedgePositions',
      'hierarchy',
      'history',
      'ignore',
      'impersonate',
      'import',
      'indices',
      'intacct',
      'integrateToIntaact',
      'layers',
      'level',
      'levels',
      'lines',
      'linkedTank',
      'list',
      'losses',
      'map',
      'margin',
      'me',
      'merge',
      'monitors',
      'monthly',
      'new',
      'nodes',
      'nodeSummary',
      'origins',
      'overrides',
      'panel',
      'paygo',
      'paygoProducts',
      'pending',
      'performance',
      'pricePlans',
      'pricingPlans',
      'product',
      'products',
      'rename',
      'reports',
      'resolve',
      'settings',
      'service',
      'serviceRework',
      'snapshot',
      'suppress',
      'stage',
      'standards',
      'statuses',
      'subscribers',
      'summary',
      'tags',
      'tanks',
      'thresholds',
      'tms',
      'toCsv',
      'totals',
      'tradeClasses',
      'transactions',
      'turns',
      'types',
      'revenues',
      'unitprices',
      'usages',
      'usageOptions',
      'users',
      'upcoming',
      'update',
      'variance',
      'vendors',
      'void',
      'years',
      'yearsForGrowth',
      'yearsForMargin',
      'yearsForVolume'
    ];

    fluentRestConfigProvider.setBaseUrl('api');

    fluentRestConfigProvider.setEndPoints(_.map(endpoints, function (endpoint) {
      return fluentRestConfigProvider.createEndPoint(endpoint);
    }));

  }]);

  agGrid.initialiseAgGridWithAngular1(angular);
