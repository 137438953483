angular.module('angus')
    .config([
        '$httpProvider', '$urlRouterProvider', '$stateProvider', '$locationProvider', '_',
        function ($httpProvider, $urlRouterProvider, $stateProvider, $locationProvider, _) {

            'use strict';

            const index = '/';
            const resetPassword = '/ResetPassword';
                // consumerResetPassword = '/Consumer/ResetPassword',
            const confirmResetPassword = '/ConfirmResetPassword';
            const impersonate = '/Impersonate';
            const privacy = '/Privacy';
            const eula = '/Eula';
            const tos = '/Tos';
            const deleteConsumerUser = '/DeleteConsumerUser';


            const systemPaths = [index, resetPassword, confirmResetPassword,/* consumerResetPassword,*/ impersonate, privacy, eula, tos, deleteConsumerUser];

            $httpProvider.defaults.headers.patch = {
                'Content-Type': 'application/json;charset=utf-8'
            };

            $urlRouterProvider
                .rule(function ($injector, $location) {
                    console.log('URL Router Provider Rule')
                    var path = $location.path().toLowerCase();

                    _(systemPaths).forEach(function (systemPath) {
                        if (path.toLowerCase().indexOf(systemPath.toLowerCase()) === 0) { //starts with
                            var beginning = path.substring(0, systemPath.length);
                            path = path.replace(beginning, systemPath);
                        }
                    });
                    $location.replace().path(path);
                })
                .otherwise(index);


            $stateProvider
                .state('legal', {
                    abstract: true,
                    controller: 'legalCtrl',
                    templateUrl: 'templates/global/html/legal.html'
                })
                .state('legal.eula', {
                    url: eula,
                    controller: 'legalCtrl',
                    templateUrl: 'templates/global/html/legal/eula.html'
                })
                .state('legal.privacy', {
                    url: privacy,
                    controller: 'legalCtrl',
                    templateUrl: 'templates/global/html/legal/privacy.html'
                })
                .state('legal.tos', {
                    url: tos,
                    controller: 'legalCtrl',
                    templateUrl: 'templates/global/html/legal/tos.html'
                })
                .state('deleteConsumerUser', {
                    url: deleteConsumerUser,
                    controller: 'deleteConsumerUserCtrl',
                    templateUrl: 'templates/global/html/consumer/consumerUserDelete.html'
                })
                .state('resetPassword', {
                    templateUrl: 'templates/auth/resetPassword/resetPassword.html',
                    url: ('{0}?token').format(resetPassword),
                    controller: 'resetPasswordCtrl'
                })
                .state('confirmResetPassword', {
                    templateUrl: 'templates/unauthenticated/html/confirmResetPassword.html',
                    url: confirmResetPassword
                })
                .state('authenticated', {
                    templateUrl: 'templates/authenticated/authenticated.html',
                    url: index,
                    resolve: {
                        userData: function(authenticationService, userService, nextGenNavigationService) {
                            const hasAccessToken = authenticationService.hasToken();
                            
                            if (!hasAccessToken) {
                                nextGenNavigationService.goToSignIn();
                                return $q.reject('Redirecting to sign in');
                            }
    
                            /* Load User Data From API */
                            return userService.loadUser();
                        }
                    },
                    controller: ['_', 'authenticationService', 'cookieService', 'dashboardsService', 'nextGenNavigationService', 'userService',
                        function (_, authenticationService,  cookieService, dashboardsService, nextGenNavigationService, userService) {                                            

                            /* Interval to check Access Token Status */
                            var intervalId = setInterval(function () {

                                /* No Token. Go To Sign In Page */
                                if (!cookieService.hasAccessToken()) {
                                    clearInterval(intervalId);
                                    nextGenNavigationService.goToSignIn();
                                    return;
                                }

                                /* Token Expired. Go to Sign in Page */
                                if (cookieService.getAccessTokenExpirationEpoch() < Date.now()) {
                                    clearInterval(intervalId);
                                    nextGenNavigationService.goToSignIn();
                                    return;
                                }

                                /* Token Expires soon. Get a new Token */
                                const expirationEpoch = cookieService.getAccessTokenExpirationEpoch();
                                const expirationSeconds = (expirationEpoch - Date.now()) / 1000;
                                if (expirationSeconds < 40 /*600*/) {
                                    authenticationService.refreshToken()
                                        .then(() => {
                                            /* Refresh UserData */
                                            userService.loadUser();
                                        });
                                    return;
                                }
                            }, 1000);

                            dashboardsService.init();
                        }
                    ]
                })
                .state('impersonate', {
                    template: `
                        <div class="dashboard-loading">
                            <div class="dashboard-loading-content" >
                                <div layout="row" layout-sm="column" layout-align="space-around">
                                    <md-progress-circular md-diameter="60" md-mode="indeterminate">Impersonating User...</md-progress-circular>
                                </div>
                            </div>
                        </div>`,
                    url: impersonate + '?subscriberId&userId',
                    controller: ['$rootScope', '$http', '$q', 'fluentRest', '$state', '$stateParams', 'authenticationService', '$window', function ($rootScope, $http, $q, fluentRest, $state, $stateParams, authenticationService, $window) {
                        if (!authenticationService.hasImpersonatedUser())
                        {
                            return $http.get(`identity/impersonate-legacy/${$stateParams.userId}`, {apiV2: true})
                            .then(function (res) {
                                const user = res.data;

                                authenticationService.impersonateUser(user.username, user);
                            })
                            .then(function() {
                                $window.location.reload(true);
                            })
                            .catch(function(err){
                                console.log('fail!: ', err);
                            });
                        }
                        else {
                            $state.transitionTo('authenticated');
                        }
                    }]
                })

            //$locationProvider.html5Mode(true);
        }
    ]).run([
    '$rootScope', '$state', '_',
    function ($rootScope, $state, _) {

            var index = '/',
            authenticated = 'authenticated',
            resetPassword = 'resetPassword',
            // consumerResetPassword = 'Consumer/ResetPassword',
            confirmResetPassword = 'confirmResetPassword',
            impersonate = 'Impersonate',
            eula = 'legal.eula',
            privacy = 'legal.privacy',
            tos = 'legal.tos',
            deleteConsumerUser = 'deleteConsumerUser';

        const nonAuthenticatedRoutes = [resetPassword, confirmResetPassword, eula, privacy, tos, deleteConsumerUser];

         function isNonAuthenticatedRoute (route) {
            return nonAuthenticatedRoutes.some((nonAuthenticatedRoute) => nonAuthenticatedRoute.toUpperCase() === route.toUpperCase() );
        }

        function isIndex (route) {
            return route.toUpperCase() === index.toUpperCase() || route.toUpperCase() === authenticated.toUpperCase();
        }

        function isImpersonate(route) {
            return route.toUpperCase() === impersonate.toUpperCase();
        }

        $rootScope.$on('$stateChangeStart', function (event, toState) {
            var destination = toState.name;

            console.log('$stateChangeStart; Destination: ', destination);

            if (isNonAuthenticatedRoute(destination)) {
                console.log('NonAuthenticated Route; Continue')
                return;
            }

            if (isImpersonate(destination)) {
                return;
            }

            if (isIndex(destination)) {
                console.log('Route Is Index; Continue');
                return;
            }

            event.preventDefault();
            $state.go(authenticated)
        });
    }
]);
