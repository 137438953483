angular.module("angus").config([
	"$httpProvider",
	function ($httpProvider) {
		$httpProvider.interceptors.push(["$q", "$window", "nextGenNavigationService",
			function ($q, $window, nextGenNavigationService) {
				return {
					request: function (config) {
                        config.header = config.header || {};

						const impersonatedUserValue = $window.sessionStorage.getItem('angus_impersonationUser');

						if (impersonatedUserValue) {
							const impersonatedUser = JSON.parse(impersonatedUserValue);
                            config.headers['iuid'] = impersonatedUser.userId;
						}

                        return config;
					},
					responseError: function (rejection) {
						if (rejection.status === 401) {
							nextGenNavigationService.goToSignIn();
						}

						return $q.reject(rejection);
					},
				};
			},
		]);
	},
]);
