angular.module('angus.services').service('userService', [
    '$http', '$q', '$rootScope', '$window', 'authenticationService', 'hierarchyHelperService', 'modalService', 'nextGenNavigationService',
    function($http, $q, $rootScope, $window, authenticationService, hierarchyHelperService, modalService, nextGenNavigationService) {

        this.loadUser = function() {

            /*** Impersonation... 
             * When a user is impersonated the user is added to Session Storage. 
             * If the impersonated User exists in Session Storage then set $rootScope.User to use it.
             *     When requests are made to the legacy api, if a iuid header is included, then the request user will be from that ID.
             * If there is no Impersonated User then get the user from the api
             * 
             * Set $rootScope.user to the impersonated user if one exists, otherwise the user from the API            
            */

            
            /* Check for Impersonated User */
            var loadUserPromise;

            if (authenticationService.hasImpersonatedUser()) {

                loadUserPromise = $q.resolve(authenticationService.getImpersonatedUser());
            } else {
                loadUserPromise = $http.get("/identity/me-legacy", {apiV2: true})
                    .then(userResponse => userResponse.data);
            }

            return loadUserPromise
                    .then(function(user){

                        user.isSysAdmin = user.role === 'sysadmin' ? true : false;

                        user.hierarchy.division.nodes = hierarchyHelperService.convertDataForEaseOfUse(user.hierarchy.division.nodes);
                        user.hierarchy.deliveryCenter.nodes = hierarchyHelperService.convertDataForEaseOfUse(user.hierarchy.deliveryCenter.nodes);
                        user.hierarchy.serviceCenter.nodes = hierarchyHelperService.convertDataForEaseOfUse(user.hierarchy.serviceCenter.nodes);
                        user.hierarchy.dealer.nodes = hierarchyHelperService.convertDataForEaseOfUse(user.hierarchy.dealer.nodes);

                        $rootScope.user = user;

                        return user;
                    });
        }

        this.signOut = function() {
            return $http.post("/identity/logout", {}, {apiV2: true})
                .then(() => {
                    /* Redirect to Sign In Page */
                    nextGenNavigationService.goToSignIn();
                });
        }

        this.impersonateUser = function(subscriberId, userId) {
            const modalOptions = {
                newWindow: 'newWindow',
                currentWindow: 'currentWindow'
            };

            $http.get(`identity/impersonate-legacy/${userId}`, {apiV2: true})
                .then(function(res){
                    const user = res.data;

                    var scope = {
                        currentWindow: modalOptions.currentWindow,
                        newWindow: modalOptions.newWindow,
                        userName: user.userName
                    };

                    return modalService.openTopBarModal('templates/global/html/impersonationSelection.html', scope)
                        .then(function (selection){
                           if (selection === modalOptions.newWindow) {
                                $window.open(('/#/Impersonate?subscriberId={0}&userId={1}').format(subscriberId, user.userId), '_blank');
                           }
                           else if (selection === modalOptions.currentWindow) {
                                authenticationService.impersonateUser($rootScope.user.username, user);
                                $window.location.reload(true);
                           }
                        });
                });
        };
    }
]);