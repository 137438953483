angular.module('angus.services').factory('cookieService', [
    function() {
        return {
            hasAccessToken: function() {
                const accessTokenExpirationEpoch = this.getAccessTokenExpirationEpoch();

                if (accessTokenExpirationEpoch) {
                    return true;
                }

                return false;
            },

            getAccessTokenExpirationEpoch: function() {
                const cookies = document.cookie.split("; ");
                const accessTokenExpirationCookie = cookies.find((row) => row.startsWith("access_token_expiration"));

                return accessTokenExpirationCookie && Number(accessTokenExpirationCookie.split('=')[1]);
            }

        }
    }
])


angular.module('angus.services').factory('authenticationService', ['cookieService', '$http', '$window',
    function(cookieService, $http, $window) {
        var impersonationKey = 'angus_impersonationUser';
        return {
            hasToken: function() {
                return cookieService.hasAccessToken();                 
            },
            refreshToken: function refreshToken() {
                return $http.get('/identity/refresh', {apiV2: true});
            },
            
            
            impersonateUser: function(currentUsername, impersonatedUser) {
                if (!impersonatedUser) {
                    return;
                }

                impersonatedUser.impersonatedBy = currentUsername;
                
                $window.sessionStorage.setItem(impersonationKey, JSON.stringify(impersonatedUser));
            },

            getImpersonatedUser: function() {
                const impersonatedUser = $window.sessionStorage.getItem(impersonationKey);

                if (!impersonatedUser) {
                    return null;
                }

                return JSON.parse(impersonatedUser);
            },

            stopImpersonating: function() {
                $window.sessionStorage.removeItem(impersonationKey);
            },
            
            hasImpersonatedUser: function() {
                var impersonatedUser = $window.sessionStorage.getItem(impersonationKey);
                return !!impersonatedUser;
            },
        };
    }
]);
