angular.module('angus.services').service('nextGenNavigationService', [
    '$location', '$window',
    function($location, $window) {

        const appBaseUrl = window.env.envApp;

        this.goToSignIn = () => {
            
            let url = `${appBaseUrl}/auth/sign-in`;

            const encodeUrl = encodeURIComponent($location.absUrl());

            if (encodeUrl) {
                url += `?returnUrl=${encodeUrl}`;
            }

            $window.location.href = url;
        }
    }
]);